@import './color';

.divider {
    border-top: 0;
    border-bottom: 1px solid $connect-lightblue;
    margin: 16px 0;
}

.divider-vertical {
    border-right: 1px solid $connect-lightblue;
    margin: 0 16px;
}

.divider,
.divider-vertical {
    &.gray-6 {
        border-color: $gray-6;
    }

    &.gray-4 {
        border-color: $gray-4;
    }

    &.gray-3 {
        border-color: $gray-3;
    }

    &.small {
        margin: 8px 0;
    }

    &.small-medium {
        margin: 24px 0;
    }

    &.medium {
        margin: 32px 0;
    }
}