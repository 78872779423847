.inset-container {
    margin-left: 16px;
    margin-right: 16px;
    
    @include media-breakpoint("gt-sm") {
        margin-left: 25px;
        margin-right: 25px;
    }

    @include media-breakpoint("gt-md") {
        margin-left: 50px;
        margin-right: 50px;
    }

    @include media-breakpoint("gt-lg") {
        margin-left: 75px;
        margin-right: 75px;
    }
}

.position-relative {
    position: relative;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.width-fit-content {
    width: fit-content;
}

.height-fit-content {
    height: fit-content !important;
}

.display-flex {
    display: flex;
}

.display-inline {
    display: inline;
}

.details-wrapper.margin-24>.detail-value {
    margin-bottom: 24px;
}

.details-wrapper.margin-24>.detail-value:last-child {
    margin-bottom: 0;
}

.flex-align-center {
    display: flex;
    gap: 0.75em;
    align-items: center;
}

.flex-col-responsive {
    display: flex;
    flex-direction: column;

    @include media-breakpoint('gt-xs') {
        flex-direction: row;
    }
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.gap-8 {
    gap: 8px;
}

.gap-16 {
    gap: 16px;
}

.gap-24 {
    gap: 24px;
}

.align-self-end {
    align-self: flex-end;
}

.justify-end {
    justify-content: flex-end;
}

.space-between {
    justify-content: space-between;
}

.invisible {
    opacity: 0;
}

*[hidden], .hidden, .hide {
    display: none !important;
}
