@import 'color';
@import 'font';
@import 'mixins';

.mat-checkbox,
.mat-card,
.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab,
.mat-list-item,
.mat-table,
.mat-tab-group {
    *:not(mat-icon, .fa, .fa-thin, .fa-light, .fa-regular, .fa-solid, .fa-brands) {
        font-family: $font;
    }
}

.mat-icon {
    color: inherit;
    margin-top: 2px;
}


.mat-form-field {
    width: 100%;

    &.no-padding {
        .mat-form-field-wrapper {
            padding: 0!important;
            margin: 0!important;
        }
    }

    .mat-form-field-infix {
        padding: 17px 0 13px 0 !important;
        width: auto !important;
        border-top: 0;
    }

    &.mat-form-field-has-label .mat-form-field-infix {
        padding: 13px 0 !important;
        border-top: 0.34375em solid rgba(0, 0, 0, 0);
     } 
    
    .mat-form-field-wrapper {
        .mat-error {
            font-size: 10px !important;
        }
        font-family: $font;
        font-size: 14px !important;
    }

    &:not(:disabled) {
        .mat-form-field-outline {
            .mat-form-field-outline-start {
               background-color: $white;
           }

           .mat-form-field-outline-gap { 
               background-color: $white;
           }
       
           .mat-form-field-outline-end { 
               background-color: $white;
           }
        }

    }

    &.mat-form-field-disabled {
        &.mat-focused .mat-form-field-label { color: $dark-grey; }
        &:not(.mat-form-field-should-float) .mat-form-field-outline .mat-form-field-outline-gap {
            border-top: 1px solid $dark-grey;
        }

        .mat-form-field-outline .mat-form-field-outline-start {
            border-left: 1px solid $dark-grey;
            border-top: 1px solid $dark-grey;
            border-bottom: 1px solid $dark-grey;
        }

        .mat-form-field-outline .mat-form-field-outline-gap {
            border-bottom: 1px solid $dark-grey;
        }

        .mat-form-field-outline .mat-form-field-outline-end {
            border-right: 1px solid $dark-grey;
            border-top: 1px solid $dark-grey;
            border-bottom: 1px solid $dark-grey;
        }

        mat-label {
            color: $dark-grey;
        }
    }

    &.mat-form-field-invalid {
        .mat-form-field-label { color: $rag-red!important; }

        .mat-form-field-outline {
             .mat-form-field-outline-start {
                border-left: 1px solid $rag-red;
                border-top: 1px solid $rag-red;
                border-bottom: 1px solid $rag-red;
            }

            .mat-form-field-outline-gap { 
                border-bottom: 1px solid $rag-red; 
            }
        
            .mat-form-field-outline-end { 
                border-right: 1px solid $rag-red;
                border-top: 1px solid $rag-red;
                border-bottom: 1px solid $rag-red;
            }

            .mat-form-field-outline-start {
                background-color: $white;
            }
        
            .mat-form-field-outline-gap { 
                background-color: $white; 
            }
        
            .mat-form-field-outline-end {
                background-color: $white;
            }
        }
        
        &:not(.mat-form-field-should-float) {
            .mat-form-field-outline {
                .mat-form-field-outline-gap {
                    border-top: 1px solid $rag-red;
                }
            }
        }
    }
    

}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.2em) scale(0.75) !important;
}

.mat-form-field-suffix:has(button) {
    align-self: center;
    margin-top: -3px;
}

.mat-tab-group {
    @extend .shadow;
    border-radius: 6px;
    background-color: $white;
    overflow: hidden;

    .mat-tab-label {
        font-weight: 700;
        opacity: 1;

        &.mat-tab-disabled {
            color: $dark-grey!important;
        }
    }
}

.mat-checkbox {
    &.mat-option-checkbox {
        .mat-checkbox-layout {
            width: 100%;
        }

        .mat-checkbox-inner-container {
            border-radius: 2px;
        }

        .mat-checkbox-frame {
            border: 2px solid rgba(0,0,0,.54);
        }
    }

    .mat-checkbox-label {
        word-spacing: normal!important;
        flex: 1;
    }
    
    &.padded .mat-checkbox-layout{
        flex: 1;
        padding: 16px 0;
    }

    &.padded .mat-checkbox-inner-container {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 0;
    }

    .mat-checkbox-inner-container, .mat-checkbox-frame {
        width: 32px;
        height: 32px;
        border-radius: 4px;
    }

    &.margin-right-16 .mat-checkbox-inner-container {
        margin-right: 16px;
    }

    &.checkbox-size-s {
        .mat-checkbox-inner-container, .mat-checkbox-frame {
            width: 24px;
            height: 24px;
        }

        .mat-checkbox-label {
            @include font-size-s;
            line-height: 24px!important;
        }
    }

    &.checkbox-size-xs {
        .mat-checkbox-inner-container, .mat-checkbox-frame {
            width: 16px;
            height: 16px;
        }

        .mat-checkbox-label {
            @include font-size-xs;
            line-height: 16px!important;
        }
    }

    .mat-checkbox-frame {
        border: 1px solid $gray-8;
    }

    .mat-checkbox-background {
        background-color: transparent!important;
        border-radius: 4px;
    }
    
    &.mat-checkbox-checked {
        .mat-checkbox-frame {
            border-color: $connect-darkblue;
        }

        .mat-checkbox-background {
            background-color: $connect-darkblue!important;
        }

        .mat-checkbox-checkmark-path {
            stroke: $white!important;
        }
    }

    .mat-checkbox-checkmark {
        fill: $connect-darkblue;
    }

    &.mat-checkbox-disabled {
        .mat-checkbox-frame {
            border-color: $gray-3!important;
        }

        &.mat-checkbox-checked {
            .mat-checkbox-background {
                background-color: $gray-3!important;
            }
        }

        .mat-checkbox-label {
            color: $gray-3!important;
        }

        .mat-checkbox-checkmark-path {
            stroke: $white!important;
        }
    }

    .mat-checkbox-label {
        @include font-size-regular;
        line-height: 32px!important;
        vertical-align: middle;
        color: $gray-8;
    }

    &.medium {
        .mat-checkbox-inner-container, .mat-checkbox-frame {
            width: 24px;
            height: 24px;
        }

        .mat-checkbox-label {
            line-height: 24px!important;
        }
    }

    &.small {
        .mat-checkbox-inner-container, .mat-checkbox-frame {
            width: 16px;
            height: 16px;
        }

        .mat-checkbox-label {
            @include font-size-xs;
            line-height: 16px!important;
        }
    }

    &.small-container {
        .mat-checkbox-inner-container, .mat-checkbox-frame {
            width: 16px;
            height: 16px;
        }
    }
}

.mat-form-field-date-picker button {
    position: relative;
}

.mat-slide-toggle span {
    font-family: "Inter", sans-serif;
}

.mat-slide-toggle.mat-checked {
    &.mat-slide-toggle-white.mat-primary {
        .mat-slide-toggle-thumb {
            background-color: $white!important;
        }
        .mat-slide-toggle-bar {
            background-color: rgba(0,0,0,.38)!important;
        }
    } 
    .mat-slide-toggle-thumb {
        background-color: $rag-green!important;
    }
    .mat-slide-toggle-bar {
        background-color: rgba($rag-green,.38)!important;
    }
}

.mat-slide-toggle.mat-slide-toggle-white .mat-slide-toggle-label {
    color: $white;
}

.mat-slide-toggle.mat-slide-toggle-reverse .mat-slide-toggle-label {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.mat-progress-bar.green {
    height: 6px !important;

    .mat-progress-bar-fill::after {
        background-color: $rag-green;
    }

    .mat-progress-bar-buffer {
        background-color: $light-grey;
    }
}

.cdk-overlay-pane {
    max-width: 100%!important;

    @include media-breakpoint('lt-sm') {
        &:not(.mat-datepicker-popup) {
            position: fixed;   
            width: 100%!important;
            height: 100%!important;
        }    
    }
}

.mat-dialog-content {
    margin: 0!important;
}

.mat-dialog-container {
    padding: 0!important;
}
@include media-breakpoint('lt-sm') {
    .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
        &:not(.mat-datepicker-popup) {

            transform: none !important;
            left: 16px !important;
            right: 16px !important;
            bottom: 0 !important;
            width: auto !important;
            height: auto !important;
            max-height: 80% !important;

            .mat-select-panel {
                min-width: calc(100% - 30px) !important;
            }
        }
    }
}

.mat-paginator-sticky {
    bottom: 0px;
    position: sticky;
    z-index: 10;
}

.mat-select-arrow-wrapper {
    transform: none !important;
}

.mat-form-field-subscript-wrapper {
    margin-top: .4em !important;
}

.mat-tab-group {
    height: 100%;

    .mat-tab-body-wrapper {
        height: 100%;
    }
}
.mat-form-field.mat-focused .mat-form-field-label {
    color: $gray-8 !important;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: $gray-8 !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: $connect-primary !important;
}

.mat-option {
    font-family: $font !important;
}

.mat-form-field-placeholder {
    color: $gray-3 !important;
}

.mat-focused .mat-form-field-placeholder {
    color: $gray-3 !important;
}


.mat-radio-container,
.mat-radio-outer-circle,
.mat-radio-inner-circle {
    width: 22px !important;
    height: 22px !important;
}

.radio-button mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: $gray-3 !important;
}

.mat-radio-button.mat-accent .mat-radio-outer-circle {
    border-color: $gray-3 !important;
}

mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
    background-color: $gray-8 !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $gray-8 !important;
}

.mat-radio-button.invalid {
    color: $connect-invalid;
}

.mat-radio-button.invalid .mat-radio-outer-circle {
    border-color: $connect-invalid !important;
}

.mdc-tooltip__surface {
    background-color: $connect-primary!important;
    color: $white !important;
    line-height: 18px !important;
    font-size: 14px !important;
}
