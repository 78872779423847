@import 'breakpoints';
@import 'color';
@import 'font';
@import 'mixins';

* {
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    text-rendering: auto;
}

html {
    height: 100%;
}

body {
    font-family: $font;
    font-size: 1em;
    height: 100%;
    margin: 0;
    background-color: $grey-050;

    app-root {
        height: 100%;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
    margin: 0;
}

p {
    margin-bottom: 20px;
}

h1.border,
h2.border,
h3.border,
h4.border,

#page {
    &.go-full-screen {
        padding: 16px !important;
        padding-top: 0 !important;
        margin: 0 !important;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100000;
        background-color: $gray-0;

        page-content {
            padding: 16px !important;
            margin: 0 !important;
        }

        .connect-banner {
            display: none;
        }

        .inset-container {
            margin: 0 !important;
            width: 100% !important;
        }

        .filter {
            margin-top: 0 !important;
            margin-bottom: 16px;
        }

        #toolbar-container.inset-container {
            margin-bottom: 16px !important;
        }
    }
}

a {
    text-decoration: none;
    cursor: pointer;
}

section {
    margin-bottom: 30px;
}

.pointer {
    cursor: pointer !important;
}

.round-corners {
    border-radius: 6px;
}

.lozenge-corners {
    border-radius: 100px;
}

.border-radius-top-5 {
    border-radius: 5px 5px 0 0;
}

.border-radius-5 {
    border-radius: 5px;
}

.border-radius-4 {
    border-radius: 4px;
}

.wrap-anywhere {
    overflow-wrap: anywhere;
}

.transition-none {
    transition: none !important;
}