.navigation-menu {
    ul {
        list-style-type: none;
        padding-left: 0;
        padding-bottom: 5px;
        display: flex;
        gap: 32px;
    }

    ul > li > button {
        border: none;
        background: none;
        margin: 0;
        font-family: $font;
    }

    a,
    button {
        text-align: center;
        cursor: pointer;
        white-space: nowrap;
        color: $gray-8;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        text-decoration: none;
    }

    a::after,
    button::after {
        content: "";
        float: left;
        width: 100%;
        height: 4px;
        border-radius: 2px;
        margin-top: 8px;
        visibility: hidden;
        transform: scaleX(0);
        transition: all .2s ease-in-out;
    }

    a:hover::after,
    button:hover::after {
        visibility: visible;
        transform: scaleX(1);
    }

    a,
    button {
        padding: 0 10px;
    }

    a:first-child,
    button:first-child {
        padding-left: 0;
    }

    a.active::after,
    a:hover::after,
    button.active::after,
    button:hover::after {
        transform: scaleX(1);
        visibility: visible;
        background-color: $connect-primary;
    }
}