@import './color';
@import './font';
@import './mixins';

.btn {
    @include font-weight-medium;
    font-family: $font;
    text-align: center;
    vertical-align: middle;
    outline: none;
    display: flex;
    justify-content: center;
    place-items: center;
    * {
        color: inherit;
        display: inline-block;
    }

    &:not(:disabled) {
        cursor: pointer;
    }

    // ******************************
    // ***        Sizes           ***
    // ******************************
    &.large, &.medium, &.small, &.x-small {
        border-radius: 40px;
    }

    &.large {
        @include font-size-regular;
        line-height: 24px;
        padding: 12px 24px;
        max-height: 48px;
        
        &.secondary {
            padding: 11px 23px;
        }

        &.icon {
            padding: 0;
            width: 48px;
            height: 48px;
           
        }
    }

    &.medium {
        @include font-size-s;
        line-height: 22px;
        padding: 8px 16px;

        &.icon {
            padding: 0;
            width: 40px;
            height: 40px;
        }

        &.secondary {
            padding: 8.5px 15px 7.5px;
        }
    }

    &.small {
        @include font-size-s;
        line-height: 22px;
        padding: 7px 12px;

        &.icon {
            padding: 0;
            width: 24px;
            height: 24px;
        }

        &.secondary {
            padding: 7px 12px;
        }
    }

    &.x-small {
        @include font-size-s;
        padding: 4px 8px;
        line-height: 22px;
    }

    &.tab {
        @include font-size-regular;
        position: relative;
        line-height: 68px;
        vertical-align: middle;
        border: 0 !important;
        &::after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: 0;
            width: 0;
            transition: all 0.2s ease-in-out;
            height: 4px;
            border-radius: 2px;
        }

        &.active {
            font-weight: 900;
        }

        &:hover::after, &.active::after {
            left: 0;
            width: 100%;
            background-color: $connect-darkblue;
        }
    }

    // ******************************
    // ***        Colors          ***
    // ******************************
    &.primary {
        border: 1px solid;

        &.dark {
            color: white;
            border-color: $connect-primary;
            background-color: $connect-primary;
            &:hover {
                background-color: $connect-darkblue;
            }
        }

        &.light {
            color: $gray-8;
            border-color: $connect-lightblue;
            background-color: $connect-lightblue;
            
            &:hover {
                background-color: $gray-3;
            }
        }

        &.white {
            color: $gray-8;
            border-color: $white;
            background-color: $white;
            &:hover {
                background-color: $connect-lightblue;
            }
        }

        &:disabled {
            color: $gray-4!important;
            border-color: $gray-2;
            background-color: $gray-2!important;
        }
    }

    &.secondary {
        color: $gray-8;
        background-color: $white;
        border: 1px solid $gray-8;

        &:hover {
            background-color: $connect-lightblue;
        }

        &.white {
            color: $white;
            border: 1px solid $white;
            background-color: transparent;
            
            &:hover {
                background-color: $white-opaque-20;
            }
        }

        &:disabled {
            color: $gray-4!important;
            border: 1px solid $gray-4;
        }

    } 

    &.positive {
        color: $connect-positive;
        border: 1px solid $connect-positive;
        background-color: transparent;
    }

    &.positive-fill {
        color: $white;
        border: 1px solid $connect-positive;
        background-color: $connect-positive;
    }

    &.neutral {
        color: $connect-alert;
        border: 1px solid $connect-alert;
        background-color: transparent;
    }

    &.neutral-fill {
        color: $white;
        border: 1px solid $connect-alert;
        background-color: $connect-alert;
    }

    &.neutral-alt {
        color: $rag-blue;
        border: 1px solid $rag-blue;
        background-color: transparent;
    }

    &.neutral-alt-fill {
        color: $white;
        border: 1px solid $rag-blue;
        background-color: $rag-blue;
    }

    &.negative {
        color: $connect-invalid;
        border: 1px solid $connect-invalid;
        background-color: transparent; 
    }

    &.negative-fill {
        color: $white;
        border: 1px solid $connect-invalid;
        background-color: $connect-invalid;
    }

    &.alert {
        color: $white;
        border: 1px solid $connect-alert;
        background-color: $connect-alert;
    }

    &.text {
        color: $connect-primary;
        background-color: transparent;
        border: none;
            
        &.white {
            color: $white;
        }
    }    
    &.transparent {
        border: 1px solid $gray-8;
        background-color: transparent;
    }
    &.empty {
        border: 0;
        padding: 0;
        background-color: transparent;
    }
    &.round-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    &.blue {
        color: $white;
        border-color: #2C7BF2;
        background-color: #2C7BF2;
    }
    &.red {
        color: $white;
        border-color: #EE6464;
        background-color: #EE6464;
    }

    // ******************************
    // ***         Icons          ***
    // ******************************
    &.primary.dark {
        i, mat-icon {
            color: $connect-accent;
        }    
    }

    &.primary.icon-white {
        i, mat-icon {
            color: $white;
        }    
    }

    i, mat-icon {
        text-align: center;
        align-self: center;
    }

    &:disabled {
        i, mat-icon {
            color: $gray-4 !important;
        }
    }

    &.icon-l {
        i, mat-icon {
            margin-right: 8px;
        }
    }

    &.icon-r {
        i, mat-icon {
            margin-left: 8px;
        }
    }

    &.large, &.text {
        i {
            @include icon-size-regular;
        }

        mat-icon {
            font-size: 24px;
        }
    }

    &.medium {
        i {
            font-size: 18px;
        }

        mat-icon {
            font-size: 22px;
        }
    }
}

