@import 'breakpoints';

.mat-tab-group {
    .mat-tab-body {
        padding: 30px;

        @include media-breakpoint('lt-md') {
            padding: 10px;
        }
    }
}