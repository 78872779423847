@import './font';

.active-check-result-wrapper {
    padding: 8px 24px 24px 24px;
}

.active-check-tiny-header {
    @extend .text-gray-6;
    @extend .font-size-xs;
    @extend .font-weight-bold;
    margin-bottom: 8px;
}

.active-check-content-wrapper {
    padding: 24px; 
}