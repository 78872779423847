.list-wrapper {
    margin: 24px 0;
}

.list-head {
    border-top: 1px solid $gray-2;
    border-bottom: 1px solid $gray-2;
    padding: 16px 24px;
    display: flex;
    flex-direction: row;
    gap: 16px;

    @include media-breakpoint('xs') {
        display: none
    }
}

.list-head-title {
    @extend .font-size-s;
    @extend .text-gray-6;
}

.list-body {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include media-breakpoint('gt-xs') {
        margin-top: 32px;
    }
}

.list-item {
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 8px;
    gap: 16px;
    
    @include media-breakpoint('gt-xs') {
        flex-direction: row;
    }
}

.list-item-transparent {
    @extend .list-item;
    border: 1px solid $gray-2;
    background-color: transparent;
}

.list-item-white {
    @extend .list-item;
    border: 1px solid $connect-lightblue;
    background-color: $white;
}

