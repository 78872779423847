@import 'color';

.skeleton-loader {
    background-color: $gray-1 !important;
    color: $gray-1 !important;
    border-radius: 4px;
    display: inline-block;
    z-index: 1;
    overflow: hidden;
    position: relative;
    transform: translate3d(0, 0, 0);

    &:before {
        background-image: linear-gradient(90deg,
                rgba(255, 255, 255, 0),
                rgba(255, 255, 255, 0.6),
                rgba(255, 255, 255, 0));
        animation: progress 2s ease-in-out infinite;
        background-size: 200px 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 200px;
        height: 100%;
        content: '';
    }
}

@keyframes progress {
    0% {
        transform: translate3d(-200px, 0, 0);
    }

    100% {
        transform: translate3d(calc(200px + 100vw), 0, 0);
    }
}