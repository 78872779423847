.padding-none {
    padding: 0 !important;
}

.padding-bottom-none {
    padding-bottom: 0 !important;
}

.padding-bottom-12 {
    padding-bottom: 12px;
}

.padding-bottom-16 {
    padding-bottom: 16px !important;
}

.padding-bottom-24 {
    padding-bottom: 24px;
}

.padding-top-none {
    padding-top: 0 !important;
}

.padding-top-12 {
    padding-top: 12px;
}

.padding-top-16 {
    padding-top: 16px;
}

.padding-top-24 {
    padding-top: 24px;
}

.padding-top-8 {
    padding-top: 8px;
}

.padding-4 {
    padding: 4px !important;
}

.padding-5 {
    padding: 5px !important;
}

.padding-8 {
    padding: 8px !important;
}

.padding-10 {
    padding: 10px !important;
}

.padding-15 {
    padding: 15px !important;
}

.padding-16 {
    padding: 16px !important;
}

.padding-20 {
    padding: 20px !important;
}

.padding-24 {
    padding: 24px !important;
}

.padding-32 {
    padding: 32px !important;
}

.padding-48 {
    padding: 48px !important;
}

.padding-y-12 {
    padding: 12px 0;
}

.padding-y-16 {
    padding: 16px 0;
}

.padding-y-24 {
    padding: 24px 0 !important;
}

.padding-y-32 {
    padding: 32px 0;
}

.padding-right-10 {
    padding-right: 10px;
}

.padding-4-16 {
    padding: 4px 16px !important;
}

.padding-16-32 {
    padding: 16px 32px;
}

.padding-left-32 {
    padding-left: 32px;
}