@import './color';
@import './mixins';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100%3B200%3B300%3B400%3B500%3B600%3B700%3B800%3B900');
@import url('https://fonts.googleapis.com/css?family=Poppins');

$font: 'Inter', sans-serif;

$font-size-xs: 0.75em; // 12 Pixels
$font-size-s: 0.875em; // 14 pixels
$font-size-regular: 1em; // 16 pixels
$font-size-l: 1.125em; // 18 pixels
$font-size-xl: 1.5em; // 24 pixels

$icon-size-s: 14px;
$icon-size-regular: 16px;
$icon-size-m: 24px;
$icon-size-l: 32px; 
$icon-size-xl: 48px;
$icon-size-xxl: 56px;
$icon-size-xxxl: 72px;
$icon-size-huge: 120px;

@mixin text-gray-1 {  color: $gray-1; }
@mixin text-gray-3 {  color: $gray-3; }
@mixin text-gray-4 {  color: $gray-4; }
@mixin text-gray-5 {  color: $gray-5; }
@mixin text-gray-6 {  color: $gray-6; }
@mixin text-gray-7 {  color: $gray-7; }
@mixin text-gray-8 { color: $gray-8; }
@mixin text-white {  color: $white; }
@mixin text-connect-primary { color: $connect-primary; }
@mixin text-connect-accent {  color: $connect-accent;  }
@mixin text-positive { color: $connect-positive; }
@mixin text-invalid { color: $connect-invalid; }
@mixin text-alert { color: $connect-alert;  }
@mixin text-italic { color: $gray-7; font-style: italic; }

@mixin font-size-xs { font-size: $font-size-xs; }
@mixin font-size-s { font-size: $font-size-s; }
@mixin font-size-regular { font-size: $font-size-regular; }
@mixin font-size-l { font-size: $font-size-l; }
@mixin font-size-xl { font-size: $font-size-xl; }

@mixin font-weight-light { font-weight: 300; }
@mixin font-weight-regular {  font-weight: 400;  }
@mixin font-weight-medium { font-weight: 500;  }
@mixin font-weight-bold { font-weight: 700 !important; }
@mixin font-weight-black { font-weight: 900 !important; }

@mixin icon-size-s { font-size:  $icon-size-s; }
@mixin icon-size-m { font-size:  $icon-size-m; }
@mixin icon-size-regular { font-size:  $icon-size-regular;  }
@mixin icon-size-l { font-size:  $icon-size-l; }
@mixin icon-size-xl { font-size:  $icon-size-xl; }
@mixin icon-size-xxl { font-size:  $icon-size-xxl; }
@mixin icon-size-xxxl { font-size:  $icon-size-xxxl; }
@mixin icon-size-huge { font-size:  $icon-size-huge; }

@mixin clamp-overflow {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
}

body {  font-family: $font; }

.font-headline { font-size: 4em; font-weight: 900; margin-block: 0.375em; line-height: 78px; }

.font-heading-1 { color: $connect-primary; font-size: 2.625em; font-weight: 700; line-height: 52px; }
.font-heading-2 { color: $connect-primary; font-size: 1.875em; font-weight: 700; line-height: 39px; }
.font-heading-3 { color: $connect-primary; font-size: 1.5em; font-weight: 700; line-height: 32px;}
.font-heading-4 { color: $connect-primary; font-size: 1.125em; font-weight: 700; line-height: 25px; margin-block: 0.500em; }
.font-heading-5 { color: $connect-primary; font-size: 1.125em; font-weight: 700; line-height: 25px; }
.font-heading-6 { color: $connect-primary; font-size: 1.125em; font-weight: 700; line-height: 25px;}
.font-heading-7 { color: $connect-primary; font-size: 1.125em; font-weight: 700; line-height: 25px;  margin-block: 0.75em}
.font-heading-8 { color: $connect-primary; font-size: 1em; font-weight: 700 }

.text-white { @include text-white(); }
.text-gray-1 { @include text-gray-1(); }
.text-gray-3 { @include text-gray-3(); }
.text-gray-4 { @include text-gray-4(); }
.text-gray-5 { @include text-gray-5(); }
.text-gray-6 { @include text-gray-6(); }
.text-gray-7 { @include text-gray-7(); }
.text-gray-8 { @include text-gray-8(); }
.text-connect-primary { @include text-connect-primary(); }
.text-connect-accent { @include text-connect-accent(); }
.text-positive { @include text-positive(); }
.text-invalid { @include text-invalid(); }
.text-alert { @include text-alert(); }
.text-italic { @include text-italic(); }

.font-size-xs { @include font-size-xs(); }
.font-size-s { @include font-size-s(); }
.font-size-regular { @include font-size-regular(); }
.font-size-l { @include font-size-l(); }
.font-size-xl { @include font-size-xl(); }
.font-size-inherit { font-size: inherit; }

.font-weight-light { @include font-weight-light(); }
.font-weight-regular { @include font-weight-regular(); }
.font-weight-medium { @include font-weight-medium(); }
.font-weight-bold { @include font-weight-bold(); }
.font-weight-black { @include font-weight-black(); }

.icon-size-s { @include icon-size-s(); }
.icon-size-m { @include icon-size-m(); }
.icon-size-regular { @include icon-size-regular(); }
.icon-size-l { @include icon-size-l(); }
.icon-size-xl { @include icon-size-xl(); }
.icon-size-xxl { @include icon-size-xxl(); }
.icon-size-xxxl { @include icon-size-xxxl(); }
.icon-size-huge { @include icon-size-huge(); }

.clamp-overflow {
    @include clamp-overflow();
    -webkit-line-clamp: 1;
}

.clamp-overflow-2 {
    @include clamp-overflow();
    -webkit-line-clamp: 2;
}

.text {
    &.headline {
        font-weight: 700;
        font-size: 24px;
        line-height: 22px;
    }

    &.sub-title {
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
    }

    &.body {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
    }

    &.label {
        font-size: 16px;
        font-weight: 600;

        &.secondary {
            font-size: 14px;
            font-weight: 500;
        }
    }

    &.italic {
        font-style: italic;
    }

    &.align-left {
        text-align: left;
    }

    &.align-center {
        text-align: center;
    }

    &.align-right {
        text-align: right;
    }

    &.font-body-large { font-size: 1.125rem; }
    &.font-body { font-size: 1rem; }
    &.font-body-small { font-size: 0.875rem; font-weight: normal; }
    &.font-body-xsmall { font-size: 0.75rem; }
    &.font-body-xxsmall { font-size: 0.625rem; }    
}

.font-caption { font-size: 0.75rem; }
.font-label { font-size: 0.875rem; }
.font-question-label { font-size: 0.875rem; font-weight: 700; margin-bottom: 4px;}
.font-table-header { font-size: 0.625rem; font-weight: 600; text-transform: uppercase; }  
.font-small-alert { font-size: 0.625rem; font-weight: 700; } 

.detail-label {
    @include font-weight-bold();
    @include font-size-xs();
    @include text-gray-6();
    margin-bottom: 6px;
}

.detail-value {
    @include text-gray-8();
    @include font-weight-regular();
    line-height: 150%;
    margin-inline: 0;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.align-left {
    text-align: left;
}

.ellipsis {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

.link {
    cursor: pointer;
    @include animate;
    display: flex;
    align-items: center;
}

a.link, .text-link {
    cursor: pointer;
    color: $connect-linkblue;
    border: 0; 
    padding: 0; 
    background-color: transparent;
    font-weight: 500;
    &:hover {
        text-decoration: underline;
    }
}

.text-decoration-none {
    text-decoration: none !important;
}

h1 { @extend .font-heading-1; }
h2 { @extend .font-heading-2; }
h3 { @extend .font-heading-3; }
h4 { @extend .font-heading-4; }
h5 { @extend .font-heading-5; }
h6 { @extend .font-heading-6; }
h7 { @extend .font-heading-7; }
h8 { @extend .font-heading-8; }