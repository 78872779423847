::-webkit-scrollbar {
    width: 13px;
    height: 13px;
}

::-webkit-scrollbar-track {
    background: $gray-0 
}

::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

.overflow-scroll {
    overflow: auto;
}

.overflow-scroll-x {
    overflow-x: auto;
}

.overflow-scroll-y {
    overflow: visible;
    max-width: 100%;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-visible {
    overflow: visible;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-y-auto {
    overflow-y: auto;
}

.invisible-scroll::-webkit-scrollbar {
    display: none;
}
