/*
    Overrides for ngx-extended-pdf-viewer
*/

ngx-extended-pdf-viewer {
    // Hide edit buttons
    #editorModeButtons, #secondaryDownload {
        display: none !important;
    }

    .invisible {
        display: none !important;
    }


    /**
        Below are style tweaks that prevent some buttons disappear when the PDF viewer is opened again without page refresh.
    **/
    @media all and (max-width: 420px) {
        #outerContainer .hiddenTinyView, #outerContainer .hiddenSmallView {
            display: none !important;
        }

        #scaleSelectContainer {
            display: none !important;
        }
    }

    @media all and (max-width: 4200px) {
        #outerContainer .hiddenTinyView,
        #outerContainer .hiddenTinyView * {
            display: inline;
        }

        #outerContainer .visibleTinyView {
            display: inline;
        }

        #outerContainer .hiddenTinyView span {
            display: none;
        }

        #outerContainer .hiddenSmallView,
        #outerContainer .hiddenSmallView * {
            display: inline;
        }

        #outerContainer .visibleSmallView {
            display: inline;
        }

        #outerContainer .hiddenSmallView span {
            display: none;
        }
    }

    @media all and (max-width: 4200px) {
        #scaleSelectContainer {
            display: inline;
        }
    }

    #outerContainer .hiddenXLView,
    #outerContainer .hiddenXXLView {
        display: inline;
    }
}
