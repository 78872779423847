// Credas 3.0 Brand colors
$rag-red: #E05050;
$rag-amber: #EFAF41;
$rag-green: #15BB8F;
$rag-blue: #317BD5;
$rag-red-light: #FCEDED;
$rag-amber-light: #FDF7EC;
$rag-green-light: #E8F8F4;
$rag-blue-light: #2C7Bf21A;
$black: #020303;
$grey-900: #1B1C1C;
$grey-800: #353535;
$grey-700: #4E4F4F;
$grey-600: #676868;
$grey-500: #818181;
$grey-400: #9A9A9A;
$grey-300: #B3B3B3;
$grey-200: #CCCDCD;
$grey-100: #E6E6E6;
$grey-050: #FAFAFA;
$background: #F5F6F8;
$divider: #E6EAEE;
$disabled: #EEF1F4;
$field-error: #FFE0E0;

$connect-primary-light: #6FCDD2;
$connect-footer: #737B7D;

$white: #FFFFFF;
$white-opaque-20: rgba(255, 255, 255, 0.2);
$gray-0: #FAFAFA;
$gray-1: #F6F6F6;
$gray-2: #EAEAEA;
$gray-3: #D4D4D4;
$gray-4: #C4C4C4;
$gray-5: #959595;
$gray-6: #757575;
$gray-7: #525252;
$gray-8: #333333;

$connect-primary: #323343;
$connect-accent: #F1A948;
$connect-lightblue: #EAEBEC;
$connect-darkblue: #282936;
$connect-invalid: #E05050;
$connect-linkblue: #317BD5;
$connect-positive: #15BB8F;
$connect-alert: #EFAF41;

.background-lightblue { background-color: $connect-lightblue; }
.background-connect-accent { background-color: $connect-accent; }
.background-positive { background-color:  $connect-positive ;}
.background-gray-0 { background-color:  $gray-0; }
.background-gray-1 { background-color:  $gray-1; }
.background-gray-2 { background-color:  $gray-2; }
.background-gray-3 { background-color:  $gray-3; }
.background-gray-4 { background-color:  $gray-4; }
.background-gray-5 { background-color:  $gray-5; }
.background-gray-6 { background-color:  $gray-6; }
.background-gray-7 { background-color:  $gray-7; }
.background-gray-8 { background-color:  $gray-8; }
.background-white { background-color:  $white; }

.border-gray-0 { border-color:  $gray-0; }
.border-gray-1 { border-color:  $gray-1; }
.border-gray-2 { border-color:  $gray-2; }
.border-gray-3 { border-color:  $gray-3; }
.border-gray-4 { border-color:  $gray-4; }
.border-gray-5 { border-color:  $gray-5; }
.border-gray-6 { border-color:  $gray-6; }
.border-gray-7 { border-color:  $gray-7; }
.border-gray-8 { border-color:  $gray-8; }

.border-width-1 { border-width: 1px; border-style: solid; }
.border-width-3 { border-width: 3px; border-style: solid; }

.drop-shadow-low { box-shadow: 0px  7px  20px rgba(0, 0, 0, 0.1); }
.drop-shadow { box-shadow: 0px  10px  40px rgba(0, 0, 0, 0.12); }
.drop-shadow-high { box-shadow: 0px  20px  60px rgba(0, 0, 0, 0.15); }

.rag-red-text { color: $connect-invalid!important; }
.rag-red-light-text { color: $rag-red-light!important; }
.rag-red-bg { background-color: $rag-red!important; }
.rag-red-light-bg { background-color: $rag-red-light!important; }

.rag-amber-text { color: $connect-alert!important; }
.rag-amber-light-text { color: $rag-amber-light!important; }
.rag-amber-bg { background-color: $rag-amber!important; }
.rag-amber-light-bg { background-color: $rag-amber-light!important; }

.rag-blue-text { color: $connect-linkblue!important; }
.rag-blue-light-text { color: $rag-blue-light!important; }
.rag-blue-bg { background-color: $rag-blue!important; }
.rag-blue-light-bg { background-color: $rag-blue-light!important; }

.rag-green-text { color: $connect-positive!important; }
.rag-green-light-text { color: $rag-green-light!important; }
.rag-green-bg { background-color: $rag-green!important; }
.rag-green-light-bg { background-color: $rag-green-light!important; }

.grey-050-bg { background-color: $grey-050; }
.grey-050-text { color: $grey-050; }
.grey-100-bg { background-color: $grey-100; }
.grey-100-text { color: $grey-100; }
.grey-200-bg { background-color: $grey-200; }
.grey-200-text { color: $grey-200; }
.grey-300-bg { background-color: $grey-300; }
.grey-300-text { color: $grey-300; }
.grey-400-bg { background-color: $grey-400; }
.grey-400-text { color: $grey-400; }
.grey-500-bg { background-color: $grey-500; }
.grey-500-text { color: $grey-500; }
.grey-600-bg { background-color: $grey-600; }
.grey-600-text { color: $grey-600; }
.grey-700-bg { background-color: $grey-700; }
.grey-700-text { color: $grey-700; }
.grey-800-bg { background-color: $grey-800; }
.grey-800-text { color: $grey-800; }
.grey-900-bg { background-color: $grey-900; }
.grey-900-text { color: $grey-900; }

.white-bg { background-color: $white; }
.white-opaque-20-bg { background-color: $white-opaque-20; }
.white-text { color: $white; }

.black-bg { background-color: $black; }
.black-text { color: $black; }

// Connect 2.0 Colors
$dark-grey: #B3ADB8;
$medium-grey: #D1D2DB;
$primary: #323343;
$light-grey: #EAEBEC;

$grey-850: #333333;
$grey-550: #757575;
$grey-350: #AAAAAA;
$grey-250: #c4c4c4;
$grey-150: #D4D4D4;
$grey-070: #EAEAEA;
$grey-050: #FAFAFA;
$grey: #F6F6F6;

.rag-red-border { border-color: $rag-red!important; }
.rag-amber-border { border-color: $rag-amber!important; }
.rag-blue-border { border-color: $rag-blue!important; }
.rag-green-border { border-color: $rag-green!important; }
.white-border { border-color: $white!important; }

.dark-grey-text { color: $dark-grey!important; }
.dark-grey-bg { background-color: $dark-grey!important; }
.dark-grey-border { border-color: $dark-grey!important; }

.medium-grey-text { color: $medium-grey!important; }
.medium-grey-bg { background-color: $medium-grey!important; }
.medium-grey-border { border-color: $medium-grey!important; }

.light-grey-text { color: $light-grey!important; }
.light-grey-bg { background-color: $light-grey!important; }
.light-grey-border { border-color: $light-grey!important; }

.grey-250-text { color: $grey-250 !important; }
.grey-350-bg { background-color: $grey-350; }
.grey-350-text { color: $grey-350; }
.grey-550-bg { background-color: $grey-550 !important; }
.grey-550-text { color: $grey-550 !important; }
.grey-550-border { border-color: $grey-550 !important; }

.greyscale {
    filter: grayscale(100%);
}

.connect-status {
 
    display: inline-block !important;
    border-radius: 4px;
    padding: 5px;
    text-align: center;
    color: $white;
    .status {
        color: $white;
    }

    &.pending {
        background-color: $rag-amber;
    }

    &.approved {
        background-color: $rag-green;
    }

    &.rejected {
        background-color: $rag-red;
    }
    &.inactive {
        border-color: $dark-grey;
        color: $dark-grey;
        background-color: $light-grey;
    }
}